import * as React from "react";
import HcpLayout from "../../../components/hcp/layout";
import { Seo } from "../../../components/seo";
import { Link } from "gatsby";
import useDeviceSize from "../../../hooks/use-device-size";
// import { BsDownload } from "react-icons/bs";
import { TfiAngleRight } from "react-icons/tfi";
import TabAccordion from "../../../components/tabAccordion";
import TabAccordionList from "../../../components/tabAccordion/tabAccordionList";
import TabAccordionCustomTab from "../../../components/tabAccordion/tabAccordionCustomTab";
import TabAccordionPanel from "../../../components/tabAccordion/tabAccordionPanel";
import questionnaireImg from "../../../images/downloads/thumb-eds-questionnaire.webp";
import cataplexyQuestionnaireImg from "../../../images/downloads/thumb-cataplexy-questionnaire.webp";
import discuussionGuideImg from "../../../images/downloads/thumb-dr-discussion-guide.webp";
import personalDiscussionImg from "../../../images/downloads/thumb-personalize-discussion.webp";
import telemedGuideImg from "../../../images/downloads/thumb-telemed-guide.webp";
import patientBrochureImg from "../../../images/downloads/thumb-patient-brochure.webp";
import docImg from "../../../images/downloads/thumb-getting-started.webp";
import w4yProcessImg from "../../../images/downloads/thumb-w4y-process-overview.webp";
import bannerImg from "../../../images/resources/hero-resources-overview.webp";
import bannerImgMobile from "../../../images/resources/hero-resources-overview-mobile.webp";
import SocialIconBlock from "../../../components/socialIconBlock";
import { StaticImage } from "gatsby-plugin-image";
import "./downloads.scss";
import InnerBanner from "../../../components/InnerBanner";

const Downloads = () => {
  const deviceSizes = useDeviceSize();
  return (
    <HcpLayout pageClass="downloads">
      <InnerBanner
        topHeading='Resources to Download'
        bannerClass="gray-bg resources"
        bannerHeroImg={bannerImg}
        bannerHeroImgMobile={bannerImgMobile}
        bannerImgAlt='Chris, a real person with narcolepsy with cataplexy taking WAKIX, sitting with his phone'
        heroNameClass="on-right"
        heroNameImg={
          <StaticImage
            src="../../../images/resources/hero-resources-overview-name.webp"
            alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
            width={231}
            height={120}
            quality={95}
            layout="fixed"
            placeholder="none"
            style={{ transition: 'opacity 0.5s' }}
            imgStyle={{ opacity: 1 }}
          />
        }
      />

      <section className="inner-first no-pb">
        <div className="centered-content">
          <div className="page-heading ph-btm">
            <h1>Downloads and Discussion Guides</h1>
            <h2>
              Learn more about narcolepsy and WAKIX and find resources for
              talking with your
              <br className="show-desktop" /> healthcare provider
            </h2>
          </div>
        </div>
      </section>

      <section className="conversation-content no-pb">
        <div className="centered-content"></div>
        <div className="tab-block gray-bg">
          <div className="centered-content">
            <TabAccordion>
              <TabAccordionList>
                <TabAccordionCustomTab>Questionnaires</TabAccordionCustomTab>
                <TabAccordionCustomTab>Discussion guides</TabAccordionCustomTab>
                <TabAccordionCustomTab>WAKIX resources</TabAccordionCustomTab>
              </TabAccordionList>

              <TabAccordionPanel id="tab1">
                <div className="conversation-info">
                  <h2>Understanding your symptoms</h2>
                  <h3 className="show-mobile mb-10">
                    Measuring your excessive daytime sleepiness (EDS)
                  </h3>
                  <div className="card-info">
                    <div className="card-info__media">
                      <img
                        src={questionnaireImg}
                        alt="Epworth Sleepiness Scale thumbnail"
                      />
                    </div>
                    <div className="card-info__content">
                      {deviceSizes?.smUp ? (
                        <>
                          <h3>
                            Measuring your excessive daytime sleepiness (EDS)
                          </h3>
                          <p>
                            Use the Epworth Sleepiness Scale (ESS) to assess
                            your level of daytime sleepiness and share the
                            results with your healthcare provider during your
                            next discussion.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="btn-wrap btn-wrap-custom">
                        <a
                          href="/pdf/Measure-excessive-daytime-sleepiness-epworth-scale.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn"
                        >
                          Download in English
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>

                          {/* <BsDownload size="26" /> */}
                        </a>
                        <a
                          href="/pdf/Measure-excessive-daytime-sleepiness-epworth-scale-spanish.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn second"
                        >
                          Download en español
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="show-mobile mb-30 mt-spacer">
                    Use the Epworth Sleepiness Scale (ESS) to assess your level
                    of daytime sleepiness and share the results with your
                    healthcare provider during your next discussion.
                  </p>

                  <h3 className="show-mobile mb-10">
                    Understanding your cataplexy
                  </h3>
                  <div className="card-info">
                    <div className="card-info__media">
                      <img
                        src={cataplexyQuestionnaireImg}
                        alt="Cataplexy assessment questionnaire thumbnail"
                      />
                    </div>
                    <div className="card-info__content">
                      {deviceSizes?.smUp ? (
                        <>
                          <h3>Understanding your cataplexy</h3>
                          <p>
                            If you have cataplexy or think you might have
                            cataplexy, use these questions to understand more
                            about your experiences and how they may be interfering
                            with your life. Share the results with your healthcare
                            provider during your next discussion.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="btn-wrap btn-wrap-custom">
                        <a
                          href="/pdf/Cataplexy-in-narcolepsy.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn"
                        >
                          Download in English
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>
                        </a>
                        <a
                          href="/pdf/Cataplexy-in-narcolepsy-spanish.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn second"
                        >
                          Download en español
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="show-mobile mb-30 mt-spacer">
                    If you have cataplexy or think you might have cataplexy, use
                    these questions to understand more about cataplexy and how
                    it may be interfering with your life. Share the results with
                    your healthcare provider during your next discussion.
                  </p>
                </div>
              </TabAccordionPanel>

              <TabAccordionPanel id="tab2">
                <div className="conversation-info">
                  <h2>Discussion guides</h2>
                  <h3 className="show-mobile mb-10">Design your discussion</h3>
                  <div className="card-info">
                    <div className="card-info__media">
                      <img
                        src={discuussionGuideImg}
                        alt="Symptom discussion guide thumbnail"
                      />
                    </div>
                    <div className="card-info__content">
                      {deviceSizes?.smUp ? (
                        <>
                          <h3>Design your discussion</h3>
                          <p>
                            When it comes to managing your EDS or cataplexy,
                            could it be time for a change? Use this guide to
                            reflect on your wants and needs and discuss them
                            with your healthcare provider.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="btn-wrap btn-wrap-custom">
                        <a
                          href="/pdf/WAKIX-doctor-discussion-guide.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn"
                        >
                          Download
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="show-mobile mb-30 mt-spacer">
                    When it comes to managing your EDS or cataplexy, could it be
                    time for a change? Use this guide to reflect on your wants
                    and needs and discuss them with your healthcare provider.
                  </p>

                  <h3 className="show-mobile mb-10">
                    Personalize your conversation
                  </h3>
                  <div className="card-info">
                    <div className="card-info__media">
                      <img
                        src={personalDiscussionImg}
                        alt="Personalized conversation plan icon"
                      />
                    </div>
                    <div className="card-info__content">
                      {deviceSizes?.smUp ? (
                        <>
                          <h3>Personalize your conversation</h3>
                          <p>
                            Ready to talk to your healthcare provider about
                            WAKIX? Use this interactive tool to answer a few
                            quick questions to get a personalized conversation
                            plan about your treatment needs to share at your
                            next appointment.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="btn-wrap btn-wrap-custom">
                        <Link
                          to="/discussion-guide/personalize"
                          className="btn btn-primary btn-primary--md"
                        >
                          Get started
                          <TfiAngleRight />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <p className="show-mobile mb-30 mt-spacer">
                    Ready to talk to your healthcare provider about WAKIX? Use
                    this interactive tool to answer a few quick questions to get
                    a personalized conversation plan about your treatment needs
                    to share at your next appointment.
                  </p>

                  <h3 className="show-mobile mb-10">
                    Prepare for a virtual appointment
                  </h3>
                  <div className="card-info">
                    <div className="card-info__media">
                      <img
                        src={telemedGuideImg}
                        alt="Guide for preparing for a virtual healthcare appointment thumbnail"
                      />
                    </div>
                    <div className="card-info__content">
                      {deviceSizes?.smUp ? (
                        <>
                          <h3>Prepare for a virtual appointment</h3>
                          <p>
                            Use this guide to help you navigate virtual
                            appointments—from setting up your technology to
                            planning what you want to discuss.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="btn-wrap btn-wrap-custom">
                        <a
                          href="/pdf/WAKIX-planning-for-virtual-appointment.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn"
                        >
                          Download
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="show-mobile mb-30 mt-spacer">
                    Use this guide to help you navigate virtual
                    appointments—from setting up your technology to planning
                    what you want to discuss.
                  </p>
                </div>
              </TabAccordionPanel>

              <TabAccordionPanel id="tab3">
                <div className="conversation-info">
                  <h2>WAKIX resources</h2>
                  <h3 className="show-mobile mb-10">
                    WAKIX Educational Brochure
                  </h3>
                  <div className="card-info">
                    <div className="card-info__media">
                      <img
                        src={patientBrochureImg}
                        alt="WAKIX educational brochure thumbnail"
                      />
                    </div>
                    <div className="card-info__content">
                      {deviceSizes?.smUp ? (
                        <>
                          <h3>WAKIX Educational Brochure</h3>
                          <p>
                            Learn about WAKIX and how it may help you manage
                            your excessive daytime sleepiness (EDS) or
                            cataplexy.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="btn-wrap btn-wrap-custom">
                        <a
                          href="/pdf/WAKIX-educational-brochure-English.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn"
                        >
                          Download in English
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>
                        </a>
                        <a
                          href="/pdf/WAKIX-educational-brochure-Spanish.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn second"
                        >
                          Download en español
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="show-mobile mb-30 mt-spacer">
                    Learn about WAKIX and how it may help you manage your
                    excessive daytime sleepiness (EDS) or cataplexy.
                  </p>

                  <h3 className="show-mobile mb-10">
                    Starting & Staying on Track With WAKIX
                  </h3>
                  <div className="card-info">
                    <div className="card-info__media">
                      <img
                        src={docImg}
                        alt="Starting and staying on track with WAKIX flashcard thumbnail"
                      />
                    </div>
                    <div className="card-info__content">
                      {deviceSizes?.smUp ? (
                        <>
                          <h3>Starting & Staying on Track With WAKIX</h3>
                          <p>
                            When starting a medication, it is important to talk
                            to your healthcare provider so he or she can help
                            you understand what to expect. This guide includes a
                            few things to discuss with your healthcare provider
                            when starting WAKIX.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="btn-wrap btn-wrap-custom">
                        <a
                          href="/pdf/WAKIX-what-to-expect-when-starting-staying-on-treatment.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn"
                        >
                          Download in English
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>
                        </a>
                        <a
                          href="/pdf/WAKIX-what-to-expect-when-starting-staying-on-treatment-spanish.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-primary--md dwn-btn second"
                        >
                          Download en español
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>

                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="show-mobile mb-30 mt-spacer">
                    When starting a medication, it is important to talk to your
                    healthcare provider so he or she can help you understand
                    what to expect. This guide includes a few things to discuss
                    with your healthcare provider when starting WAKIX.
                  </p>

                  <h3 className="show-mobile mb-10">
                    <span className="italic blue">WAKIX for You</span> Overview
                  </h3>
                  <div className="card-info">
                    <div className="card-info__media">
                      <img
                        src={w4yProcessImg}
                        alt="WAKIX for You overview brochure thumbnail"
                      />
                    </div>
                    <div className="card-info__content">
                      {deviceSizes?.smUp ? (
                        <>
                          <h3>
                            <span className="italic blue">WAKIX for You</span>{" "}
                            Overview
                          </h3>
                          <p>
                            Navigating the reimbursement process can be
                            challenging. If you’ve been prescribed WAKIX,{" "}
                            <span className="italic blue pro-bold">
                              WAKIX for You
                            </span>{" "}
                            provides the support to help you get started on
                            WAKIX. Here is an overview of the program and
                            services available.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="btn-wrap btn-wrap-custom">
                        <a
                          href="/pdf/WAKIX-for-you-patient-support-program.pdf"
                          target="_blank"
                          className="btn btn-primary btn-primary--md dwn-btn"
                        >
                          Download
                          <svg id="download_arrow" data-name="download arrow" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21" height="22" viewBox="0 0 21 22">
                            <defs>
                              <clipPath id="clip-path">
                                <path id="Clip_4" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              </clipPath>
                            </defs>
                            <path id="Fill_1" data-name="Fill 1" d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5" transform="translate(0 17)" fill="#fff" />
                            <g id="Group_5" data-name="Group 5" transform="translate(9)">
                              <path id="Clip_4-2" data-name="Clip 4" d="M0,0H2V16H0Z" fill="none" />
                              <g id="Group_5-2" data-name="Group 5" clipPath="url(#clip-path)">
                                <path id="Fill_3" data-name="Fill 3" d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16" fill="#fff" />
                              </g>
                            </g>
                            <path id="Fill_6" data-name="Fill 6" d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8" transform="translate(3 9)" fill="#fff" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="show-mobile mb-30 mt-spacer">
                    Navigating the reimbursement process can be challenging. If
                    you’ve been prescribed WAKIX,{" "}
                    <span className="italic blue pro-bold">WAKIX for You</span>{" "}
                    provides the support to help you get started on WAKIX. Here
                    is an overview of the program and services available.
                  </p>
                </div>
              </TabAccordionPanel>
            </TabAccordion>
          </div>
        </div>
      </section>
      <SocialIconBlock />
    </HcpLayout>
  );
};

export default Downloads;

export const Head = () => (
  <Seo
    title="Resources to Download | WAKIX® (pitolisant)"
    description="Download symptom questionnaires and educational materials about narcolepsy and WAKIX. Find discussion guides for talking with your healthcare provider"
    keywords="WAKIX resources, WAKIX information, WAKIX education, WAKIX downloads, pitolisant resources, pitolisant information, narcolepsy resources, narcolepsy education"
  />
);
